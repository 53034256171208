import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getProfile } from '@/store/selectors';
import Button from '../Button/Button';
import UserInfo from '../UserInfo/UserInfo';

import styles from './AccountPreview.module.scss';
import { getCashbackCode } from '@/api';

export function AccountPreview(props) {
	const { onClickProfile, onClickAppointment, onClickSignOut } = props;

	const profile = useSelector(getProfile);

	const [code, setCode] = useState();
	const [currentMinute, setCurrentMinute] = useState(Math.floor(Date.now() / 60 / 1000) * 60 * 1000);
	const [countdown, setCountdown] = useState(Date.now() - Math.floor(Date.now() / 60 / 1000) * 60 * 1000);

	const handleClickProfile = useCallback((e) => {
		e.stopPropagation();
		onClickProfile?.();
	}, [onClickProfile]);

	const handleClickAppointment = useCallback((e) => {
		e.stopPropagation();
		onClickAppointment?.();
	}, [onClickAppointment]);

	const handleClickSignOut = useCallback((e) => {
		e.stopPropagation();
		onClickSignOut?.();
	}, [onClickSignOut]);

	useEffect(() => {
		const i = setInterval(() => {
			if (currentMinute + 60 * 1000 < Date.now()) {
				setCurrentMinute(currentMinute + 60 * 1000);
				getCashbackCode().then((generatedCode) => {
					setCode(generatedCode);
				});
			}
			setCountdown(Date.now() - currentMinute);
		}, 1000);
		return () => clearInterval(i);
	}, [currentMinute]);

	useEffect(() => {
		getCashbackCode().then((generatedCode) => {
			setCode(generatedCode);
		});
	}, []);

	return (
		<div className={styles.container}>
			<UserInfo user={profile}/>
			<div className={styles.visitsInfo}>
				Активные записи: {profile.appointmentsCount} | Подарки: {profile.giftsCount}
			</div>
			<div className={styles.cashbackCodeContainer}>
				<div className={styles.cashbackCodeTitle}>
					Код для списания кешбэка
				</div>
				<div className={styles.cashbackCode}>
					{code}
				</div>
				<div className={styles.cashbackCountdown}>
					обновится через {60 - Math.floor(countdown / 1000)}
				</div>
			</div>
			<div className={styles.actions}>
				<Button onClick={handleClickProfile} className={styles.profile}>
					Личный кабинет
				</Button>
				<Button onClick={handleClickAppointment} className={styles.appointmentButton}>
					Записаться
				</Button>
				<button onClick={handleClickSignOut} className={styles.signOut}>
					Выход
				</button>
			</div>
		</div>
	);
}

export default AccountPreview;
