import request from '../utils/request';
import { waitRecaptcha } from '@/utils/waitRecaptcha';
import { fileToBase64 } from '@/utils/fileToBase64';
import { Storage } from '@/utils/storage';

export async function loadSalons() {
	return await request.get('/salons');
}

export async function loadPage(path) {
	return await request.get('/page-data', {
		params: { path },
	});
}

export function loadSettings() {
	return request.get('/settings');
}

export async function loadServices() {
	return await request.get('/services');
}

export async function loadNews(cityId, page = 0, perPage = 6) {
	return await request.get(`/pages/news-page/?page=${page}&perPage=${perPage}&cityId=${cityId}`);
}

export async function loadProfile() {
	return await request.get('/profile');
}

export async function editProfile(data) {
	return await request.post('/profile', data);
}

export async function loadVisits(page = 0, perPage = 10) {
	return request.get(`/profile/appointments?page=${page}&perPage=${perPage}`);
}

export async function loadReiviewableVisits() {
	return request.get('/profile/appointments/reviewable');
}

export async function loadAvailableTime(salonId, services, date) {
	try {
		return await request.post('/appointment/free-time', { salon: salonId, services, date });
	} catch (e) {
		return [];
	}
}

export async function submitAppointment({
	salon,
	services,
	date,
	time,
	id = null,
	comment,
	notification,
}, quickSignUpToken) {
	if (id) {
		await request.post(`/appointment/${id}`, { salon, services, date, time, comment, notification });
	} else {
		const config = {};
		if (quickSignUpToken) {
			config.headers = {
				'Authorization': `Bearer ${quickSignUpToken}`,
			};
		}
		const utm = Storage.getItem('utm');
		const result = await request.post('/appointment', { salon, services, date, time, comment, notification, utm }, config);
		Storage.deleteItem('utm');
		return result;
	}
}

export async function submitVisitReview(id, answers, comment, photos = []) {
	return await request.post('/profile/review', {
		id,
		answers,
		comment,
		photos: await Promise.all(photos.map(fileToBase64)),
	});
}

export async function submitAbstractReview(comment, photos) {
	await request.post('/profile/review', {
		comment,
		photos: await Promise.all(photos.map(fileToBase64)),
	});
}

export async function cancelVisit(id, reason, comment) {
	return await request.delete(`/profile/appointments/${id}`, { data: { reason, comment } });
}

export async function quickSignUp(form) {
	return await request.post('/auth/quick-sign-up', form);
}

export const signIn = async ({ phone, password }) => {
	return await request.post('/auth/sign-in', { phone, password });
};

export const signUpCheck = async (form) => {
	return await request.post('/auth/sign-up/check', form);
};

export const signUp = async (form) => {
	return await request.post('/auth/sign-up', form);
};

export const confirmPhone = async ({ phone, purpose }, action = 'confirm') => {
	await waitRecaptcha();
	const token = await window.grecaptcha.execute('6Lcug4waAAAAAAaKrIj0VQRvQMbQhhalzVl6uswq', { action });
	return request.post('/auth/confirm-phone', { phone, purpose, token });
};

export const refreshToken = async () => {
	return await request.post('/auth/refresh');
};

export const initCertificatesOrder = async (order) => {
	return await request.post('/gift-cards/order', order);
};

export const checkCertificatesOrderStatus = async (orderId) => {
	return await request.get('/gift-cards/status', {
		params: { orderId },
	});
};

export const fetchGifts = async () => {
	return await request.get('/profile/gifts');
};

export const fetchCashback = async () => {
	return await request.get('/profile/cashback');
};

export const getCashbackCode = async () => {
	return await request.post('/profile/generate-cashback-code');
};

export const checkClientIsRegistered = async (phone) => {
	return await request.post('/auth/check-client', {
		phone,
	});
};

export const changePassword = async ({ phone, code, password }) => {
	return await request.post('/auth/change-password', {
		phone,
		password,
		code,
	});
};
